import React, { Component } from "react";
import Select from 'react-select';

import Pagination from 'react-js-pagination';

import Notification from "../../utils/api/notification";

import NotificationListItem from './NotificationListItem';

import 'bootstrap/dist/css/bootstrap.css';
class NotificationList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notification: [],
      orderBy: 'sentDateTime',
      sort: 'ascending',
      selectedOptionSystem: null,
      selectedOptionTypeMessage: null,
      search: '',
      activePage: 1,
      totalItemsCount: 1,
      totalPages: 1,
      itemsCountPerPage: 10,
      pageRangeDisplayed: 5
    }
  }

  optionsSystem = [
    { value: 'SMS', label: 'SMS' },
    { value: 'PUSH', label: 'push' },
    { value: 'EMAIL', label: 'Email' },
  ];

  optionsTypeMessage = [
    { value: 'in bounds', label: 'in bounds' },
    { value: 'out of bounds', label: 'out of bounds' },
    { value: 'lost connection', label: 'lost connection' },
    { value: 'low level battery', label: 'low level battery' },
  ];

  componentDidMount() {
    this.fetchNotification()
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber
    }, this.fetchNotification);
  }

  searchLocation = (event) => {
    this.setState({
      search: event.target.value,
      activePage: 1
    }, this.fetchNotification);
  }

  fetchNotification = () => {
    Notification.getAllNotification({
      orderBy: this.state.orderBy,
      sort: this.state.sort,
      filterSystem: this.state.selectedOptionSystem == null ? '' : this.state.selectedOptionSystem.value,
      filterTypeMessage: this.state.selectedOptionTypeMessage == null ? '' : this.state.selectedOptionTypeMessage.value,
      search: this.state.search == null ? '' : this.state.search,
      page: this.state.activePage
    }).then(data => {
      console.log('Notification', data);
      // get total amount of whole range items
      setTimeout(() => {
        this.setState({
          totalItemsCount: data.data.meta.totalItems,
          totalPages: data.data.meta.totalPages
        });
      }, 0);

      data && data.data.items && this.setState({
        notification: data.data.items.map(item => {
          return {
            _id: item._id,
            location: item.location,
            freezer: item.freezer.friendlyName || item.freezer.name,
            sentDateTime: item.sentDateTime,
            typeMessage: item.typeMessage,
            system: item.system
          }
        })
      })
    });
  }

  setOrderByAndSort = (orderBy, currSortState) => {
    if (orderBy) {
      this.setState({
        orderBy: orderBy
      });
    }
    if (!currSortState) {
      this.setState({
        sort: 'ascending'
      }, this.fetchNotification);
    } else if (currSortState === 'ascending') {
      this.setState({
        sort: 'descending'
      }, this.fetchNotification);
    } else if (currSortState === 'descending') {
      this.setState({
        sort: 'ascending'
      }, this.fetchNotification);
    }
  }

  handleRemove = (id) => {
    Notification.deleteNotification(id);
    this.fetchNotification()
  }

  handleFilterSystem = selectedOptionSystem => {
    this.setState({ selectedOptionSystem }, this.fetchNotification);
    console.log('option selected', selectedOptionSystem);
  }

  handleFilterTypeMessage = selectedOptionTypeMessage => {
    this.setState({ selectedOptionTypeMessage }, this.fetchNotification);
    console.log('option selected', selectedOptionTypeMessage);
  }

  render() {

    return <main className="wrapper wrapper__pharmacys">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
          <div className="grid__header">
              <div></div>
              <input type="text" style={{ marginLeft: 10 + 'px' }} onChange={(e) => this.searchLocation(e)} />
            </div>
          </div>
          <div className="col"></div>
          <div className="col"></div>
         
          <div className="col">
            <Select  
              value = {this.state.selectedOptionTypeMessage}
              options = {this.optionsTypeMessage}
              onChange = {this.handleFilterTypeMessage}
            />
          </div>
          <div className="col">
          <Select  
              value = {this.state.selectedOptionSystem}
              options = {this.optionsSystem}
              onChange = {this.handleFilterSystem}
            />
          </div>
          <div className="col">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.itemsCountPerPage}
              totalItemsCount={this.state.totalItemsCount}
              pageRangeDisplayed={this.state.pageRangeDisplayed}
              onChange={this.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="grid__header">
              <div>#</div>
              <div className="grid__subheader">
                <div>Location name</div>
                <div>Freezer</div>
                <div onClick={() => this.setOrderByAndSort('sentDateTime', this.state.sort)} style={{ cursor: 'pointer' }}>
                  sentDateTime {(this.state.sort && ((this.state.sort === 'ascending' || this.state.sort === 'descending') && this.state.sort === 'ascending' ? '↑' : '↓'))}
                </div>
                <div>type Message</div>
                <div>system notification</div>
              </div>
            </div>
          </div>
        </div>
        {this.state.notification.length ? this.state.notification.map((item, index) => {
          return (
            <NotificationListItem {...this.props} itemNumber={index + 1} data={item} key={item._id} handleRemoveChild={this.handleRemove} />
          )
        }) :
          <div>No exists notification </div>
        }
        <div className="row">
          <div className="col">
            <div className="grid__header">
              <div></div>
            </div>
          </div>
          <div className="col">
            <div className="item__name" style={{ paddingTop: '0.58em' }}>
              page {this.state.activePage} of {this.state.totalPages} pages ( for {this.state.totalItemsCount} notifications )</div>
          </div>
          <div className="col">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.itemsCountPerPage}
              totalItemsCount={this.state.totalItemsCount}
              pageRangeDisplayed={this.state.pageRangeDisplayed}
              onChange={this.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
      </div>
    </main>
  }
}

export default NotificationList
